import Vue from 'vue'
import Router from 'vue-router'
const Authorization = () => import(/* webpackChunkName: "user" */ 'app/components/Authorization')
const Cabinet = () => import(/* webpackChunkName: "content" */ 'app/components/Cabinet')
const CabinetEGC = () => import(/* webpackChunkName: "content" */ 'app/components/CabinetEGC')
const CabinetMyGift = () => import(/* webpackChunkName: "content" */ 'app/components/CabinetMyGift')
const Catalog = () => import(/* webpackChunkName: "catalog" */ 'app/components/Catalog')
const Order = () => import(/* webpackChunkName: "catalog" */ 'app/components/Order')
const Verification = () => import(/* webpackChunkName: "tax" */ 'app/components/Verification')
const Upload = () => import(/* webpackChunkName: "tax" */ 'app/components/Upload')
const DownloadAct = () => import(/* webpackChunkName: "tax" */ 'app/components/DownloadAct')
const Contacts = () => import(/* webpackChunkName: "content" */ 'app/components/Contacts')
const AdminTasks = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminTasks')
const AdminUserEdit = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminUserEdit')
const AdminUsersList = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminUsersList')
const AdminOrdersList = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminOrdersList')
const AdminOrdersStatistics = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminOrdersStatistics')
const AdminTransactions = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminTransactions')
const AdminTransactionsUpload = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminTransactionsUpload')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Cabinet',
      component: Cabinet,
      meta: {
        title: 'Личный кабинет участника',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/egc',
      name: 'CabinetEGC',
      component: CabinetEGC,
      meta: {
        title: 'Электронные Подарочные Сертификаты',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ],
          path: '/'
        }
      }
    },
    {
      path: '/mygift',
      name: 'CabinetMyGift',
      component: CabinetMyGift,
      meta: {
        title: 'Пластиковые карты MyGift',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ],
          path: '/'
        }
      }
    },
    {
      path: '/auth',
      name: 'Authorization',
      component: Authorization,
      meta: {
        title: 'Авторизация',
        permissions: {
          roles: [
            'guest'
          ],
          path: '/cabinet'
        }
      }
    },
    {
      path: '/catalog',
      name: 'Catalog',
      component: Catalog,
      meta: {
        title: 'Выберите подарок',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/order',
      name: 'Order',
      component: Order,
      meta: {
        title: 'Оформление заказа',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/verification',
      name: 'Verification',
      component: Verification,
      meta: {
        title: 'Подтверждающие документы',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/upload/:taskId?',
      name: 'Upload',
      component: Upload,
      meta: {
        title: 'Загрузка файлов',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/download-act/:year/:taskId?',
      name: 'DownloadAct',
      component: DownloadAct,
      meta: {
        title: 'Распечатать акт',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: Contacts,
      meta: {
        title: 'Контакты',
        permissions: {
          roles: [
            '*'
          ]
        }
      }
    },
    {
      path: '/admin/tasks',
      name: 'AdminTasks',
      component: AdminTasks,
      meta: {
        title: 'Управление заданиями',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/user/:userId',
      name: 'AdminUserEdit',
      component: AdminUserEdit,
      meta: {
        title: 'Редактирование пользователя',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/users/list',
      name: 'AdminUsersList',
      component: AdminUsersList,
      meta: {
        title: 'Список пользователей',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/orders/list',
      name: 'AdminOrdersList',
      component: AdminOrdersList,
      meta: {
        title: 'Список заказов',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/orders/statistics',
      name: 'AdminOrdersStatistics',
      component: AdminOrdersStatistics,
      meta: {
        title: 'Статистика заказов',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/transactions/list',
      name: 'AdminTransactions',
      component: AdminTransactions,
      meta: {
        title: 'Транзакции',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/transactions/upload',
      name: 'AdminTransactionsUpload',
      component: AdminTransactionsUpload,
      meta: {
        title: 'Загрузка транзакций',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
